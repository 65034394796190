.achievements-container {
  min-height: 550px;
  background-color: #000;
}
.achievements-container h3 {
  font-family: "montserrat", sans-serif;
  font-size: 35px;
}
.achivments-container {
  width: 80%;
  margin: auto;
  color: white;
}
.achivments-container ul li {
  font-family: "montserrat", serif;
  font-size: 16px;
  font-weight: 300 !important;
  padding: 0px !important;
}
.achievements-container h5 {
  font-family: "montserrat", sans-serif;
}
.achivments-number {
  font-family: "montserrat", sans-serif;
}
.achivments-number div {
  width: 250px;
}
.achivments-number div h2 {
  font-family: "montserrat", sans-serif;
}
.achivments-number div p {
  font-family: "montserrat", sans-serif;
  font-size: 1.0rem;
  font-weight: 100 !important;

}
.achivments-number {
  justify-content: space-between;
}
@media screen and (max-width: 966px) {
  .achivments-container {
    width: 100%;
  }
  .achivments-number {
    justify-content: center !important;
  }
}
.achivments-container ul {
  list-style: none; /* Removes bullet points */
  padding-left: 0;  /* Removes left padding from the ul */
  margin-left: 0;   /* Ensures no margin on the left */
}

.achivments-container ul li {
  font-family: "montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300 !important;
  padding-left: 0 !important;  /* Removes left padding from li */
  margin-left: 0 !important;   /* Removes left margin from li */
}
.about-us-title {
  font-weight: bold !important;
  color: white !important; /* Ensure it's white */
  visibility: visible !important; /* Ensure it's not hidden */
  display: block !important; /* Ensure it's displayed */
  text-align: center;
}

