.carDetails-container .header ul a li {
  color: #fff;
  text-shadow: 1px 1px 33px black;
  transition: 0.3s ease;
}
.carDetails-container .header .header-humb-icon {
  color: #000 !important;
}
.carDetails-container .header {
  position: relative;
}

.carDetails-container .ul-mobile a li {
  color: #fff !important;
}
.carDetails-container .header ul a:hover li {
  color: #d9d9d9;
}

.car-grid-container {
  width: 100%;
}
.car-grid-container .row {
  width: 100%;
}
.slider-container {
  width: 100%;
}
.mySwiper2 {
  width: 100%;
  height: 500px;
  border-radius: 15px;
}
.description-container h3 {
  font-size: 30px;
}
.description-container span {
  font-size: 20px;
}
.show-hide-btn {
  color: #777;
  text-decoration: underline !important;
  font-size: 14px !important;
  display: block;
  cursor: pointer;
}

.car-extensions {
  background-color: #fff;
  border-radius: 6px;
}
.car-extensions-container {
  background-color: #ededed;
}

.video {
  height: 450px;
}
.features ul li {
  color: #888a8b;
  position: relative;
}
.features ul li::before {
  position: absolute;
  content: "";
  width: 10px;
  background-color: #888a8b;
  height: 10px;
  border-radius: 100%;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.features ul {
  column-gap: 50px;
  row-gap: 10px;
}
.location {
  padding-top: 15rem !important;
  height: 500px;
}
.send-message-container {
  width: 100%;
  min-height: 400px;
  background-color: #ededed;
}
.send-message-container .row {
  margin: auto !important;
}

.contact-info-container {
  width: 100%;
}
.contact-info-container form input {
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  border: 1px solid #dadbdd;
}
.contact-info-container form textarea {
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  border: 1px solid #dadbdd;
  width: 100%;
  height: 160px;
}
.contact-info-container form button {
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  width: 200px;
  background-color: #888a8b;
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/* PhoneInputInput */
.PhoneInputInput {
  background-color: #fff !important;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  border: 1px solid #dadbdd;
  padding: 10px;
}
.contact-info {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.contact-info span:nth-child(1) {
  color: #888a8b;
}
.telephone {
  width: 80% !important;
  margin: auto;
  border: none;
  outline: none;
  background-color: #fff;
  border: 1px solid #dadbdd;
 
}
.related-listings-container {
  min-height: 450px;
  /* background-color: #222732; */
}

.related-listings-container .mySwiper {
  position: relative;
  height: 100%;
  padding-bottom: 100px;
}
.related-listings-container .new-arrival-feature {
  width: auto;
  color: #fff !important;
  background-color: #222732 !important;
  border-radius: 8px;
}
.related-listings-container .new-arrival-feature-img {
  height: 250px;
  border-radius: 8px 8px 0 0;
}
.related-listings-container .new-arrival-feature-img img {
  border-radius: 8px 8px 0 0;
}
.related-listings-container .new-arrival-btns button:nth-child(2) {
  background-color: #888a8b;
  color: #fff;
  border-radius: 5px;
}
.related-listings-container .new-arrival-btns button:nth-child(1) {
  background-color: #99a1b2;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
}
.related-listings-container .new-arrival-infos .info {
  color: #99a1b2;
}

.related-listings-container .new-arrival-feature .car-name {
  font-size: 14px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
.related-listings-container .new-arrival-feature .car-price {
  font-size: 14px !important;
}
.related-listings-container .mySwiper .swiper-button-next {
  color: #000;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-bottom: 30px;
}
.related-listings-container .mySwiper .swiper-button-prev {
  color: #000;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.related-listings-container .mySwiper .swiper-button-prev {
  position: absolute;
  left: 90%;
  top: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.related-listings-container .mySwiper .swiper-button-next {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  right: 0;
  top: 90%;
}

.related-listings-container .mySwiper .swiper-button-next:after {
  font-size: 20px;
}
.related-listings-container .mySwiper .swiper-button-prev:after {
  font-size: 20px;
}
.seller-info-ul {
  border-bottom: 1px solid #a7a8a8;
}
.seller-info-ul li {
  color: #a7a8a8;
}
.seller-info-ul li:not(.li-first) {
  position: relative;
}
.seller-info-ul li:not(.li-first)::before {
  content: "";
  left: -15px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: #888a8b;
  border-radius: 100%;
}
.side-car-price {
  color: #888a8b;
}
.car-informations {
  min-height: 250px;
  background-color: #ededed;
  border-radius: 8px;
}
.car-informations div {
  width: 50%;
}
.side-contact {
  border: 1px solid #dadbdd;
  border-radius: 8px;
}
.side-contact span {
  color: #888a8b;
}
.seller-info .telephone {
  width: 100%;
  border-radius: 8px;
}
.seller-info .whatsUp {
  background-image: linear-gradient(180deg, #36b330 0%, #3eaf39 100%);
  color: #fff;
}
.seller-info .send-msg {
  background-color: #888a8b;
  color: #fff;
}

.seller-info {
  position: relative;
}
.seller-info .wrapper {
  top: -450px;
  position: sticky;
}
.car-informations {
  min-height: 300px;
}
.slide {
  height: 450px;
}
.slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.down-slide {
  height: 150px;
  border-radius: 8px;
  padding: 10px;
}
.down-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px !important;
}
.wrapper-mobile {
  display: none;
}

@media screen and (max-width: 850px) {
  form input {
    width: 100%;
    margin-bottom: 10px;
  }
  form .phone.PhoneInput {
    width: 100%;
  }
  .contact-info-container {
    margin: auto;
  }
  .car-informations {
    padding: 10px !important;
  }
  .contact-on-whats{ 
    color:#fff;
    
    width: 100%;
  }
  
}
@media screen and (max-width: 1200px) {
  .wrapper-mobile {
    display: block;
  }
}

.wrapper-mobile button {
  width: 100%;
  border-radius: 8px;
}
.wrapper-mobile .whatsUp {
  background-image: linear-gradient(180deg, #36b330 0%, #3eaf39 100%);
  color: #fff;
}
.wrapper-mobile .send-msg {
  background-color: #888a8b;
  color: #fff;
}
.contact-info-container .row {
  padding: 0 !important;
}
.car-informations {
  padding: 30px 50px;
}
.carDetails-container .car-info{
  display: block;
}


.button {
  background-color: #007bff; /* Blue background */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px; /* 10px top and bottom, 20px left and right */
  font-size: 16px; /* Medium text */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for background color and transform */
  outline: none; /* Removes the outline */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow */
}

.button:hover, .button:focus {
  background-color: #0056b3; /* Darker blue background on hover/focus */
  transform: translateY(-2px); /* Slightly raise the button */
  box-shadow: 0 4px 10px rgba(0,0,0,0.3); /* Increase shadow size */
}

.button:active {
  transform: translateY(1px); /* Slightly press down the button */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Revert shadow size */
}

.input-error {
  border-color: red; /* Red border for error inputs */
}

.error-message {
  color: red; /* Red text for error messages */
  margin-left: 15px; /* Align with the input field */
}

.currency-selector {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  outline: none;
  margin-right: 10px; /* Adjust space between the selector and the price */
}

.currency-selector:hover {
  border-color: #888;
}

.currency-selector:focus {
  border-color: #555;
}

.contact-on-whats{ 
  color:#fff;
}

.close-contact-options {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  color: orange; 
  background-color: orange !important;
}

/* CarDetails.css */
.view-button {
  background-color: transparent; /* Blue color, adjust as needed */
  color: white !important;
  padding: 15px !important;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  color: white;
  text-decoration: none;
}


.sticky-container {
  position: -webkit-sticky; 
  position: sticky;
  top: 20px; 
  background-color: white; 
  padding: 10px 15px;
  z-index: 10; 
  margin: 20px 0; 
  border: 1px solid #ddd; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 5px; 
}

.new-arrival-btns button {
  border: none !important;
  outline: none !important;
  background: none !important;
  font-size: 12px !important;
}
.new-arrival-btns button:nth-child(1) {
  color: #fff !important;
  background-color: #000 !important;
  width: 100% !important;
}
.new-arrival-btns button:nth-child(2) {
  color: #000 !important;
  background-color: #d9d9d9 !important;
  width: 100% !important;
}

.link-view-button {
  flex: 1 !important;
 
  color: #000 !important;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease !important;
}
.btn-interest:hover, .btn-interest:focus, .link-view-button:hover, .link-view-button:focus {
  transform: scale(1.05) !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
  outline: none !important;
}