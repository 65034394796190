.partners-container {
  min-height: 300px;
  overflow: hidden;
  background-color: #000;
}

.partners-container h6 {
  color: #fff;
  font-family: "montserrat", sans-serif;
  font-size: 2.5rem !important;
  font-weight: bold;
  margin-bottom: 4rem;
}

.scroll {
  width: 100%;
  color: #fff;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
  padding-bottom: 10px;
  scrollbar-width: thin; /* For Firefox */
  cursor: grab; /* For draggable feature */
  user-select: none; /* Disable text selection while dragging */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  
  display: flex;
  justify-content: center; /* Center the scrolling container */
}

.scroll:active {
  cursor: grabbing;
}

.scroll::-webkit-scrollbar {
  height: 10px; /* Height of the scrollbar */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #000; /* Track color */
}

.partner-logo {
  margin-right: 20px;
  display: inline-flex; /* Keep logos in a row */
  justify-content: center;
  align-items: center;
}

.partner-img {
  height: 100px; 
  width: auto;
  object-fit: contain; 
}

/* Media query for responsive adjustments */
@media (max-width: 768px) {
  .partner-img {
    height: 80px;
  }
}
