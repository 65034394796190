.blog-post-container {
  padding: 2rem 0;
  background-color: black;
}

.blog-post {
  text-align: center;
}
.title{ 
  color: white !important;
}

.blogs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.blog-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 1rem;
  overflow: hidden;
  transition: transform 0.3s;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card:hover {
  transform: translateY(-10px);
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-body {
  padding: 1rem;
}

.blog-card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.blog-card-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-card-source-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff !important;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.blog-card-source-btn:hover {
  background-color: #fac000;
}
