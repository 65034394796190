@media screen and (max-width: 1200px) {
  .new-arrivals-features {
    justify-content: center !important;
  }
}
.new-arrivals {
  min-height: 600px;
  background-color: #000;
}
.new-arrivals h1 {
  color: #fff;
  font-family: "Monteserat", sans-serif;
}
.new-arrival-feature-img {
  height: 350px;
}



.new-arrival-feature {
  width: 360px;
  background-color: #fff;
  justify-content: space-between;
}
.new-arrival-feature-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.new-arrival-infos .info {
  font-size: 0.8rem;
  color: #999999;
}
.new-arrival-infos {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
}
.new-arrival-btns button {
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
}
.new-arrival-btns button:nth-child(1) {
  color: #fff;
  background-color: #000;
  width: 100%;
}
.new-arrival-btns button:nth-child(2) {
  color: #000;
  background-color: #d9d9d9;
  width: 100%;
}
.view-stock-btn {
  background-color: #d9d9d9;
  padding: 10px 20px;
  border-radius: 40px;
  margin: auto !important;
  width: 150px;
}

.title-and-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.price {
  color: #000; /* Example color for the price */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Style for when the modal is open to blur the background */
.App.modal-open {
  filter: blur(2px);
}

.App {
  transition: filter 0.5s ease;
}

.App.modal-open {
  filter: blur(3px);
}

/* Modal Form Styles */
.modal-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Makes sure padding doesn't affect the total width */
}


.modal-form button.close-button {
  padding: 5px 10px;
  background-color: transparent;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  float: right; /* Aligns the button to the right */
}

.modal-form button.close-button:hover {
  background-color: #f8f9fa; /* Light gray background on hover */
}
.modal-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical; /* Allows vertical resize only */
}

.cta {
  border: none;
  background: none;
  cursor: pointer;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.contact-options-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: slideUp 0.3s ease-out;
  z-index: 1000;
  width: 280px; /* Adjust the width as needed */
}



.title-container {
  flex-grow: 1;
}

.title-ellipsis {
  margin: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%; /* Ensure the element fills the container */
  min-width: 0; /* Critical to allow the ellipsis to apply correctly */
}