.blog-details {
  min-height: 450px;
  background-color: #000;
}

.blog-details-features {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; /* Keep in a row for larger screens */
  gap: 2rem; /* Space between blocks */
  margin: auto;
}

.blog-details h1 {
  font-family: "montserrat", sans-serif;
  color: #fff;
}

.main-title {
  font-family: "montserrat", sans-serif;
  font-weight: bold;
}

.blog-details-container {
  margin: auto;
  text-align: left;
}

.blog-details-container p {
  font-family: "montserrat", sans-serif;
  color: #fff;
}

.blog-details-container span {
  font-family: "montserrat", serif;
}

.feature {
  width: 70%;
  text-align: start;
}

.first-letter {
  color: #c4a048; /* Customize the first letter's color */
  font-weight: bold;
}

.title-color {
  color: white;
}

.title-limited {
  max-width: 100%;
  word-wrap: break-word;
  display: block;
}

.title-word {
  white-space: nowrap;
}

/* Vertical divider for larger screens */
.divider {
  width: 2px;
  height: 100%;
  margin-left: 15px;
}

/* Responsive Adjustments */
@media screen and (max-width: 993px) {
  .blog-details-container h1.title {
    text-align: center !important;
    font-size: 35px !important;
    margin-bottom: 40px !important;
  }

  .feature {
    width: 100% !important;
  }

  .blog-details-container {
    width: 100%;
  }

  /* Switch to horizontal divider on mobile */
  .divider {
    width: 100%; /* Full width */
    height: 2px; /* Flat horizontal line */
    margin-left: 0;
    margin-top: 20px;
  }

  .blog-details-features {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch;
  }
}
